import {React,  } from "react";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import "./style.css"
const MenuSection = ({ menus }) => {
  
  const location = useLocation();

  useEffect(() => {
      // Extract hash from URL for matching ID purposes
      const hash = location.hash;
      
      if (hash) {
          // Scroll to the element with the ID matching the hash
          const element = document.getElementById(hash.replace('#', ''));
          if (element) {
              element.scrollIntoView({ behavior: 'smooth' });
          }
      }
  }, [location]);

    if (!menus || menus.length === 0) return null; // Handle empty or undefined menus

    return (
      <section className="menuSection">
        {menus.map((menuObject) => (
          <div key={menuObject._id} id={`${menuObject.value}-menu`}>
  
            {menuObject.value === "food"  ? (
              <div className="sub-menu-list">
                {menuObject.options.map((option) => (
                  <div key={option.id} id={`submenu-${option.subMenu}`} >
                    {/* SubMenu Header */}
                    <div className="menu-header">
                      <div className="hr"></div>
                      <p className="menu-item-category poppins-medium">{option.subMenu}</p>
                      <div className="hr"></div>
                    </div>

                    {(option.metadata) ?
                      <div className="metadata" >
                        <p style={{textTransform: 'uppercase',}}>{option.metadata}</p>
                      </div>
                    : ''}
  
                    {/* Meals Content */}
                    <div className="menu-content">
                      {option.meals.map((meal) => (
                        <div key={meal.id} className="menu-content-item">
                          <div className="left">
                            <div className="menu-item-name">{meal.name}</div>
                            <div className="menu-item-info">{meal.info}</div>
                          </div>
                          <div className="right">
                            <div className="menu-item-cost">{meal.cost}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <>
                <div className="wine-section">
                  {menuObject.options.map((wine) => (
                    <div key={wine.id}  id={`submenu-${wine.subMenu}`}>
  
                      {/* SubMenu Header */}
                      <div className="menu-header">
                        <p className="menu-item-category poppins-medium">{wine.subMenu}</p>
                      </div>

                      {/* Wine Content */}
                      <div className="menu-content">
                        {wine.meals.map((glass) => (
                          <div key={glass.id} className="menu-content-item">
                            <div className="left">
                              
                              <div className="details">
                                <div className="menu-item-name">{glass.name}</div>
                                <div className="menu-item-info">{glass.info}</div>
                                {glass.year && (
                                  <div className="year">- {glass.year}</div>
                                )}
                              </div>
                            </div>
                            <div className="right">
                              <div className="menu-item-cost">{glass.cost}</div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </section>
    );
  };
  
  export default MenuSection;
  