import React from "react";
import { Link,} from "react-router-dom";
import logo from "./../../../src/images/Rose Garden.png";
import fbIcon from "./../../../src/images/facebook-icon.png";
import igIcon from "./../../../src/images/mdi_instagram.png";
import tkIcon from "./../../../src/images/tiktok.png";
import Header from "../header";
import "./style.css";

const Shia = () => {
  return (
    <div className="homepage">
      {/* Navbar */}
      <Header/>

      {/* Main Content */}
      <div className="main-content">
        <div>
          <div className="logo">
            <img
              src={logo}
              alt="rose gardens logo"
            />
          </div>
          <div className="slogan">
            <span  className="poppins-medium">BREAKFAST IS OUR THING...</span> <span className="poppins-medium">WE LOVE IT HERE!</span>
          </div>{" "}
        </div>
      </div>

      <div className="social-icons">
        <Link to="https://m.facebook.com/100037145086778/"  target="_blank" rel="noopener noreferrer">
          <img src={fbIcon} alt="facebook-icon" width="48px"/>
        </Link>
        <Link to="https://www.instagram.com/rosegardengh/profilecard/?igsh=dzFvd29jcjNiZWo5"  target="_blank" rel="noopener noreferrer">
          <img src={igIcon} alt="instagram-icon" width="48px" />
        </Link>
        <Link to="https://www.tiktok.com/@rosegardengh?_t=8rzaz05bAEH&_r=1"  target="_blank" rel="noopener noreferrer">
          <img src={tkIcon} alt="tiktok-icon" width="48px"/>
        </Link>
      </div>

    </div>
  );
};

export default Shia;
