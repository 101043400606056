import {React,useState} from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./styles.css"
import Logo from "./../../../src/images/Rose Garden.png";
import { menuOptions } from "../../menu";


function Header({activePageStr}) {
  const menuItems = ["menu", "reservation", "about-us", "contact"];
  const filteredMenuItems = menuItems.filter((item) => item !== activePageStr);
  const location = useLocation();
  const isActive = (path) => location.pathname.includes(path) ? 'active' : ''; // Check if the current path includes the page


  const navigate = useNavigate();

  const handleSubMenuClick = (section) => {
    // Navigate to the MenuPage with the section ID in the query params
    navigate(`/menu#${section}`);
  };
  const [isNavOpen, ] = useState(false);
  const [activePopup, setActivePopup] = useState(null); 

  // const toggleNav = () => setIsNavOpen(!isNavOpen);

  //   const openPopup = (popupType) => setActivePopup(popupType);
  const closePopup = () => setActivePopup(null);

  const openPopup = (popupType, event) => {
    const button = event.target.getBoundingClientRect();
    setActivePopup({
      type: popupType,
      position: {
        top: button.bottom, // Place below button
        left: button.left + window.scrollX, // Align horizontally with button
        right: button.right + window.scrollX //align right with button
      },
    });
  };

  const popupListStyle =  {
    padding: '8px 20px'
  }

  return (
    <>
        <header className="navbar">
            <div className="img-container" >
            <Link to="/">
              <img src={Logo} alt="logo" />
            </Link>
            </div>
            <nav className={`nav-links ${isNavOpen ? "active" : ""}`}>
              <button onClick={(event) => openPopup("menu", event)} className={`poppins-semibold ${isActive("/menu") }`}>Menu</button>
              <Link to="/reservation"><button className={`poppins-semibold ${isActive("/reservation") }`}> Reservation </button></Link>
              <Link to="/about-us"><button className={`poppins-semibold ${isActive("/about-us") }`}> About Us </button></Link>
              <Link to="/contact"><button className={`poppins-semibold ${isActive("/contact") }`}> Contact </button></Link>
            </nav>
            <button className="hamburger" onClick={(event) => openPopup("hamburger", event)}>
            ☰
            </button>
        </header>

        {/* Popup Modals */}
        {activePopup && (
        <div className="popup-overlay" onClick={closePopup}>
          <div
            className="popup-content"
            onClick={(e) => e.stopPropagation()}
            style=  {(activePopup.type !== "hamburger") ? {
              position: "absolute",
              top: `${activePopup.position.top}px`,
              left: `${activePopup.position.left}px`,
            }: {
              position: "absolute",
              top: `${activePopup.position.top}px`,
              right: `${activePopup.position.right - activePopup.position.left}px`,
            }} 
          >
            <button className="close-popup" onClick={closePopup}>
              &times;
            </button>
            {activePopup.type === "menu" && (
              <ul>
                {menuOptions.map((menuOption) => (
                  <li style={popupListStyle} key={menuOption._id} onClick={() => handleSubMenuClick(`${menuOption.value}-menu`)}>{menuOption.viewValue} Menu</li>
                ))}
              </ul>
            )}
            {activePopup.type === "hamburger" && 
                <ul>
                  {filteredMenuItems.map((item) => (
                    <li key={item} style={popupListStyle} onClick={()=> navigate(`/${item}`)}>{item.replace('-', ' ')}</li>
                  ))}
                </ul>
            }
          </div>
        </div>
      )}
    </>
    
  )
}

export default Header