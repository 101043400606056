import { React, useState, useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import "./style.css";
import { menuOptions } from "../../menu";
import MenuSection from "../menu-item";
import MenuSlider from "../menu-slider";
import { workingHours } from "../../working-hours";
import Header from "../header";
import Footer from "../footer";
import Banner from "../banner";

const Menu = () => {
  const [activePage, ] = useState("menu");
  
  const location = useLocation();

  const menuOptionsObj = {
    food: 0,
    drinks: 1,
  }
  const initialSelectedMenu = location.hash.split('-')[0].slice(1)
  const initialSelectedMenuIndex = menuOptionsObj[initialSelectedMenu]
  // State to track the currently selected menu and its submenu items
  const [selectedMenu, setSelectedMenu] = useState((initialSelectedMenu) ? menuOptions[initialSelectedMenuIndex] : menuOptions[0]); // Default to the first menu

  const scrollToSubMenu = (subMenuId) => {
    const element = document.getElementById(`submenu-${subMenuId}`);
    const isMobile = window.innerWidth <= 500; // Example breakpoint offsetMenuNavMobile

    if (element) {
      const offsetNavbar = document.querySelector(".navbar")?.offsetHeight || 0;
      const offsetMenuNav = document.querySelector(".menu-nav")?.offsetHeight || 0;
      const offsetMenuNavMobile = document.querySelector(".menu-nav")?.offsetHeight * 1.5 || 0;
      const offsetBanner = document.querySelector(".banner")?.offsetHeight || 0;
      const offset = (isMobile ? offsetMenuNavMobile : offsetMenuNav) + offsetNavbar + offsetBanner; // Adjust this value based on your layout
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
      });
    }
  };

  const [activePopup, setActivePopup] = useState(null);
  const closePopup = () => setActivePopup(null);
 
  const handleMenuChange = (e) => {
    const selectedValue = e.target.value;
    const menu = menuOptions.find(
      (menuOption) => menuOption.value === selectedValue
    );
    setSelectedMenu(menu); // Update the state to reflect the selected menu
  };

  useEffect(() => {
    if (selectedMenu.options.length > 0) {
      const firstSubMenu = selectedMenu.options[0].subMenu;
      scrollToSubMenu(firstSubMenu);
    }
  }, [selectedMenu]);

  const isOpen = (workingHours) => {
    const now = new Date();
    const day = now.toLocaleDateString("en-US", { weekday: "long" });
    const currentTime = now.getHours() * 60 + now.getMinutes(); // Convert to minutes

    if (workingHours[day]?.open === "Closed") return false;

    const [openHour, openMinutes] = workingHours[day].open
      .replace(/[^0-9:]/g, "")
      .split(":")
      .map(Number);
    const [closeHour, closeMinutes] = workingHours[day].close
      .replace(/[^0-9:]/g, "")
      .split(":")
      .map(Number);

    const openTime = openHour * 60 + openMinutes;
    const closeTime = closeHour * 60 + closeMinutes;

    return currentTime >= openTime && currentTime < closeTime;
  };

  const [, setIsCurrentlyOpen] = useState(false);

  useEffect(() => {
    setIsCurrentlyOpen(isOpen(workingHours));
  }, []);

  const [showHoursPopup, setShowHoursPopup] = useState(false);

  const toggleHoursPopup = () => setShowHoursPopup(!showHoursPopup);

  const [isSticky, setIsSticky] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isMobile = window.innerWidth <= 500; // Example breakpoint offsetMenuNavMobile
      const offsetNavbar = document.querySelector(".navbar")?.offsetHeight || 0;
      const offsetMenuNav = document.querySelector(".menu-nav")?.offsetHeight || 0;
      const offsetMenuNavMobile = document.querySelector(".menu-nav")?.offsetHeight * 1.5 || 0;
      const offsetBanner = document.querySelector(".banner")?.offsetHeight || 0;
      const offset = (isMobile ? offsetMenuNavMobile : offsetMenuNav) + offsetNavbar + offsetBanner; 
      // const offset = 700; // Adjust this value for when the nav becomes sticky
      setIsSticky(window.scrollY > offset);

      // Show "Scroll to Top" button when scrolled down enough
      setShowScrollToTop(window.scrollY > offset); // Button appears after 300px scroll
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Enables smooth scrolling
    });
  };

  return (
      <div>
        <div className="container">
        <Header activePageStr={activePage}/>
        <Banner/>
          <div className="content">
            <div className="heading">
              <div className={`menu-nav ${isSticky ? "sticky" : ""}`}>
                <fieldset className="menu-selection">
                  <legend>Choose a Menu</legend>
                  {/* Dropdown for selecting a menu */}
                  <select onChange={handleMenuChange} value={selectedMenu.value}>
                    {menuOptions.map((menuOption) => (
                      <option key={menuOption._id} value={menuOption.value}>
                        {menuOption.viewValue} Menu
                      </option>
                    ))}
                  </select>
                </fieldset>
                <>
                  <MenuSlider options={selectedMenu.options} />
                </>
              </div>
            </div>
            <div className="main">
                  <MenuSection  menus={menuOptions}  />
            </div>
          </div>
        <Footer/>

        </div>

        {showHoursPopup && (
          <div className="hours-popup">
            <h3>Working Hours</h3>
            <ul>
              {Object.entries(workingHours).map(([day, hours]) => (
                <li key={day}>
                  <strong>{day}:</strong>{" "}
                  {hours.open === "Closed" ? "Closed" : `${hours.open} - ${hours.close}`}
                </li>
              ))}
            </ul>
            <button onClick={toggleHoursPopup}>Close</button>
          </div>
        )}

        {/* Scroll to Top Button */}
        {showScrollToTop && (
          <button className="scroll-to-top" onClick={scrollToTop}>
            ↑
          </button>
        )}
      

      {activePopup && (
        <div className="popup-overlay" onClick={closePopup}>
          <div
            className="popup-content"
            onClick={(e) => e.stopPropagation()}
            style={{
              position: "absolute",
              top: `${activePopup.position.top}px`,
              left: `${activePopup.position.left}px`,
            }}
          >
            <button className="close-popup" onClick={closePopup}>
              &times;
            </button>
            {activePopup.type === "hamburger" && 
                <ul>
                    <Link to="/"><li> Home </li></Link> 
                    <Link to="/contact"><li>Contact</li></Link> 
                </ul>
            }
          </div>
        </div>)}
      </div>
  );
};

export default Menu;
