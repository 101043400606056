import {React, useEffect, useState} from 'react'
import { Link, } from "react-router-dom";
import reserve from "./../../../src/images/RoseGardenGH.svg";
import Header from "../header";
import "./style.css";
import Footer from '../footer';
import Banner from '../banner';


export default function Reservation() {
    const [activePage, ] = useState("reservation");
    useEffect(() => {
        window.scrollTo({
            top: 0,
        });
    }, []);

    return (
        <div className="reservation">
          {/* Navbar */}
          <Header activePageStr={activePage}/>
          <Banner/>
        
          {/* Main Content */}
          <div className="reservation-content">
            <div className='reservation-card'>
                <div className='qr-code-container'>
                    <img src={reserve} alt="rose gardens gh reservation qr"/>
                </div>
                <div className='reservation-info-content'>
                    <div className='reservation-banner'>
                        <p className='poppins-regular'> Scan the QR Code or click <Link to="https://docs.google.com/forms/d/e/1FAIpQLSc8BmLCZpP1U20lvWagbOF2EfGJMNYGzWwqOPMLZ6HXB0bb2w/viewform?usp=dialog">here</Link> to reserve your table effortlessly.
                        </p>
                    </div>
                    <div className='reservation-info'>
                        <p className='poppins-regular'>  Welcome to Rosé Garden Reservations!  </p>
                        <p className='poppins-regular'>  Thank you for choosing Accra's favorite breakfast spot! </p>                    

                        <p className='poppins-regular'> Our team will assist you in securing your spot and ensuring a delightful dining experience.  </p>
                    </div>
                    <div className="reservation-working-hours">
                        <h4  className="reservation-working-hour-title poppins-bold">Opening Hours</h4>            
                        <div className='opening-hrs'>
                            <div>
                                <p><strong>Tuesday - Friday:</strong></p>
                                <p>8:00 am to 5:00 pm</p>
                            </div>
                            <div>
                                <p><strong>Saturday - Sunday:</strong> </p>
                                <p>8:00 am to 6:00 pm</p>
                            </div>
                             
                        </div>
                    </div>
                </div>               
            </div>
          </div>
    
          
          <Footer/>
        </div>
    );
}
