export const workingHours = {
    Monday: { open: "9:00 AM", close: "5:00 PM" },
    Tuesday: { open: "9:00 AM", close: "5:00 PM" },
    Wednesday: { open: "9:00 AM", close: "5:00 PM" },
    Thursday: { open: "9:00 AM", close: "5:00 PM" },
    Friday: { open: "9:00 AM", close: "5:00 PM" },
    Saturday: { open: "10:00 AM", close: "4:00 PM" },
    // Sunday: { open: "10:00 AM", close: "9:00 PM" },
    Sunday: { open: "Closed", close: "Closed" },
  };
  