import React, { useState, useEffect } from "react";
import "./style.css";
import prevIcon from "./../../images/prev-icon.svg"
import nextIcon from "./../../../src/images/next-icon.svg"

const MenuSlider = ({ options }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // Track the starting index of visible items
  const [activeItem, setActiveItem] = useState(null); // Track the active menu item

  // Set the active item to the first item in the options list when the component mounts or options change
  useEffect(() => {
    if (options.length > 0) {
      setActiveItem(options[0].id);
    }
  }, [options]);

  const itemsPerView = 5; // Adjust the number of visible items in the list
  const maxIndex = Math.max(0, options.length - itemsPerView);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex));
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleActiveMenu = (menuItem) => {
    setActiveItem(menuItem.id);
    scrollToSubMenu(menuItem.subMenu);
  }

  const scrollToSubMenu = (subMenuId) => {
    // document.getElementById(`submenu-${subMenuId}`)?.scrollIntoView({
    //   behavior: "smooth",
    // });
    const element = document.getElementById(`submenu-${subMenuId}`);
    const isMobile = window.innerWidth <= 500; // Example breakpoint offsetMenuNavMobile

    if (element) {
      const offsetNavbar = document.querySelector(".navbar")?.offsetHeight || 0;
      const offsetMenuNav = document.querySelector(".menu-nav")?.offsetHeight || 0;
      const offsetMenuNavMobile = document.querySelector(".menu-nav")?.offsetHeight * 1.5 || 0;
      const offsetBanner = document.querySelector(".banner")?.offsetHeight || 0;
      const offset = (isMobile ? offsetMenuNavMobile : offsetMenuNav) + offsetNavbar + offsetBanner; // Adjust this value based on your layout
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - offset;

      window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
      });
    }
  };

  const slice = (arg) => (arg.length > 15) ? arg.slice(0, 15) +"..." : arg;
  

  return (
    <div className="slider-container">
      <div className="btn-container prev" onClick={handlePrev}>
         <img src={prevIcon} alt="previous icon" style={{display: currentIndex === 0  ? 'none' : 'block'}}/>
      </div>
      <div className="menu-slider">
        <ul className="menu-list">
          {options.map((item, index) => (
            <li
              key={item.id}
              className={`menu-item ${
                index >= currentIndex && index < currentIndex + itemsPerView
                  ? "visible"
                  : "hidden"
              } ${activeItem === item.id ? "active" : ""}`}
              onClick={() => handleActiveMenu(item)}

            >
              {slice(item.subMenu) || "No SubMenu"}
            </li>
          ))}
        </ul>
      </div>
      <div className="btn-container next" onClick={handleNext}>
          <img src={nextIcon} alt="next icon" style={{display: currentIndex === maxIndex  ? 'none' : 'block'}}/>
      </div>
    </div>
  );
};

export default MenuSlider;
