import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.css';
import Menu from './components/menu';
import Shia from './components/shia';
import Contact from './components/contact';
import AboutUs from './components/about-us';
import ErrorPage from './components/error';
import Reservation from './components/reservation';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Shia />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/reservation" element={<Reservation />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;
