export const menuOptions = [
    {
        "_id": 0,
        "value": "food",
        "viewValue": "Food",
        "options": [
            {
                "subMenu": "omelettes",
                "meals": [
                    {
                        "name": "VEGETABLE OMELETTE",
                        "info": "THREE EGG OMELETTE FILLED WITH WITH SAUTÉED TOMATOES, ONION,AND GREEN PEPPER",
                        "cost": 95,
                        "id": 0
                    },
                    {
                        "name": "SPINACH & MUSHROOM OMELETTE",
                        "info": "THREE EGG OMELETTE FILLED WITH SPINACH AND MUSHROOMS, TOPPED WITH CHEDDAR CHEESE",
                        "cost": 115,
                        "id": 1
                    },
                    {
                        "name": "FULLY LOADED OMELETTE",
                        "info": "THREE EGG OMELETTE FILLED WITH SAUSAGE, BACON, TOMATOES, ONIONS, GREEN PEPPERS, MUSHROOM, SPINACH AND CHEESE",
                        "cost": 130,
                        "id": 2
                    },
                ],
                "id": 0,
                "metadata": "*ALL OMELETTES ARE SERVED WITH A SIDE OF HASHBROWN AND TOAST | *ADD CHEESE GHS 30"
            },
            {
                "subMenu": "breakfast skillets",
                "meals": [
                    {
                        "name": "FULLY LOADED BREAKFAST SKILLET",
                        "info": "BREAKFAST POTATOES, SAUSAGE, BACON, BELL PEPPERS, ONIONS, TOPPED WITH EGG – SUNNY SIDE UP AND SPRINKLED WITH PARMASEAN CHEESE ",
                        "cost": 145,
                        "id": 0
                    },
                    {
                        "name": "VEGGIE BREAKFAST SKILLET",
                        "info": "BREAKFAST POTATOES, MUSHROOM, ONIONS, BELL PEPPERS, TOPPED WITH EGG – SUNNY SIDE UP AND SPRINKLED WITH PARMASEAN CHEESE ",
                        "cost": 115,
                        "id": 1
                    },
                ],
                "id": 1
            },
            {
                "subMenu": "fancy toast",
                "meals": [
                    {
                        "name": "THE AVO STACK",
                        "info": "LOADED AVOCADO ON TOAST WITH BABY TOMATOES AND ARUGULA, TOPPED WITH RED PEPPER FLAKES ",
                        "cost": 125,
                        "id": 0
                    },
                    {
                        "name": "THE SMOKED SALMON STACK",
                        "info": "LOADED AVOCADO ON TOAST WITH SMOKED SALMON, TOPPED WITH RED PEPPER FLAKES",
                        "cost": 190,
                        "id": 1
                    },
                    {
                        "name": "THE MUSHROOM AND AVO STACK",
                        "info": "LOADED AVOCADO ON TOAST, WITH A CREAMY MUSHROOM SAUCE, TOPPED WITH RED PEPPER FLAKES",
                        "cost": 140,
                        "id": 2
                    },
                ],
                "metadata": "*ADD EGG GHS 35 | *ADD BACON GHS 55",
                "id": 2
            },
            {
                "subMenu": "PLATTERS",
                "meals": [
                    {
                        "name": "BBQ FEAST",
                        "info": "BBQ PORKK RIBS, BBQ CHICKEN, PORK SAUSAGE, CHICKEN WINGS, CORN WITH FRIES AND RICE",
                        "cost": 520,
                        "id": 0
                    },
                    {
                        "name": "BREAKFAST PLATTER",
                        "info": "PANCAKES, FRENCH TOAST, WAFFLES, BUTTERMILK FRIED CHICKEN, *EGGS YOUR WAY, PORK BACON, SAUSAGE AND HASH BROWNS",
                        "cost": 495,
                        "id": 1
                    },
                ],
                "metadata": "EGGS: SCRAMBLED, FRIED, SUNNY SIDE UP | SAUSAGE: PORK OR CHICKEN | *ASK YOUR WAITER ABOUT OUR DELICIOUS MAPLE BUTTER",
                "id": 3
            },
            {
                "subMenu": "THE CLASSICS",
                "meals": [
                    {
                        "name": "AMERICAN BREAKFAST",
                        "info": "FLUFFY PANCAKES WITH *EGGS YOUR WAY, SAUSAGE, PORK BACON AND HASH BROWN",
                        "cost": 160,
                        "id": 0
                    },
                    {
                        "name": "FRENCH TOAST",
                        "info": "SUGAR BREAD FRENCH TOAST TOPPED WITH ICING SUGAR AND A MEDLEY, SERVED WITH SAUSAGE AND PORK BACON",
                        "cost": 155,
                        "id": 1
                    },
                    {
                        "name": "ENGLISH BREAKFAST",
                        "info": "CLASSIC BREAKFAST WITH *EGGS YOUR WAY, SAUSAGE, PORK BACON, MUSHROOMS, HASH BROWN, TOMATO, BAKED BEANS AND TOAST",
                        "cost": 165,
                        "id": 2
                    },
                    {
                        "name": "BUTTERMILK FRIED CHICKEN AND WAFFLES",
                        "info": "CRISPY BUTTERMILK FRIED CHICKEN ON OUR CLASSIC WAFFLES, TOPPED WITH ICING SUGAR AND A BERRY MEDLEY",
                        "cost": 160,
                        "id": 3
                    },
                    {
                        "name": "CHICKEN WING AND FRIES",
                        "info": "CRIPSY GOLDEN FRIED CHICKEN WINGS TOSSED IN A SAUCE OF YOUR CHOICE",
                        "cost": 135,
                        "id": 4
                    },
                ],
                "id": 4
            },
            {
                "subMenu": "sweeten it up",
                "meals": [
                    {
                        "name": "lotus",
                        "info": "",
                        "cost": 45,
                        "id": 0
                    },
                    {
                        "name": "oreo",
                        "info": "",
                        "cost": 45,
                        "id": 1
                    },
                    {
                        "name": "strawberry short cake",
                        "info": "",
                        "cost": 45,
                        "id": 2
                    },
                    {
                        "name": "blueberry",
                        "info": "",
                        "cost": 45,
                        "id": 3
                    },
                    {
                        "name": "confetti",
                        "info": "",
                        "cost": 45,
                        "id": 4
                    },
                    {
                        "name": "tres leches",
                        "info": "",
                        "cost": 45,
                        "id": 5
                    },
                ],
                "id": 10, //
                "metadata":"make your pancakes, waffles or french toast a little sweeter by choosing: "
            },
            {
                "subMenu": "heavy hitters",
                "meals": [
                    {
                        "name": "the big stack",
                        "info": "FLUFFY PANCAKE STACK, TOPPED WITH CRISPY FRIED WINGS DRIZZLED WITH MAPLE SYRUP",
                        "cost": 130,
                        "id": 0
                    },
                    {
                        "name": "breakfast loaded fries",
                        "info": "CRISPY FRIES LOADED WITH SCRAMBLED EGGS , PORK BACON, SAUSAGE, VEGETABLES AND CHEESE",
                        "cost": 140,
                        "id": 1
                    },
                    {
                        "name": "steak & eggs",
                        "info": "BUTTERY BEEF STEAK WITH EGGS YOUR WAY SERVED WITH BREAKFAST POTATOES SAUTÉED WITH ONIONS AND GREEN PEPPERS",
                        "cost": 200,
                        "id": 2
                    },
                    {
                        "name": "breakfast tacos",
                        "info": "THREE FLUFFY PANCAKE TACOS FILLED WITH SCRAMBLED EGGS, BACON AND CHEESE ,TOPPED WITH A DRIZZLE OF MAPLE SYRUP",
                        "cost": 95,
                        "id": 3
                    },
                    {
                        "name": "double chicken & waffles",
                        "info": "CLASSIC WAFFLE WITH TWO BUTTERMILK FRIED CHICKEN BREASTS, BACON, TOPPED WITH EGGS – SUNNY SIDE UP",
                        "cost": 185,
                        "id": 4
                    },
                ],
                "id": 5
            },
            {
                "subMenu": "light bites",
                "meals": [
                    {
                        "name": "chicken wings",
                        "info": "GOLDEN FRIED CHICKEN WINGS COATED WITH A SAUCE OF YOUR CHOICE",
                        "cost": 100,
                        "id": 0
                    },
                    {
                        "name": "ROSÉ PUFF PUFF & SHRIMP",
                        "info": "OUR SIGNATURE PUFF PUFF SERVED WITH GOLDEN FRIED SHRIMPS",
                        "cost": 155,
                        "id": 1
                    },
                    {
                        "name": "salt & pepper calamari",
                        "cost": 105,
                        "id": 2
                    },
                ],
                "id": 6
            },
            {
                "subMenu": "salads, sandwiches & burgers",
                "meals": [
                    {
                        "name": "SPRING MIX SALAD",
                        "info": "MIXED GREENS, CABBAGE, CUCUMBERS, CARROTS, ONIONS AND BABY TOMATOES",
                        "cost": 150,
                        "id": 0
                    },
                    {
                        "name": "SOUTHWESTERN SALAD",
                        "info": "MIXED GREENS, TOPPED WITH BLACK BEANS, CORN, TOMATOES, ONION AND TORTILLA CHIPS",
                        "cost": 165,
                        "id": 1
                    },
                    {
                        "name": "GRILLED CHEESE SANDWICH",
                        "info": "MELTED CHEESE (CHEDDAR AND MOZZARELLA ) SANDWICH STUFFED WITH PORK BACON, SERVED WITH FRENCH FRIES",
                        "cost": 150,
                        "id": 2
                    },
                    {
                        "name": "SPICY SOUTHERN CHICKEN BURGER",
                        "info": "CRISPY CHICKEN SANDWICH TOPPED WITH A SPICY MAYO, COLESLAW AND PICKLES, SERVED WITH FRENCH FRIES ",
                        "cost": 165,
                        "id": 3
                    },
                    {
                        "name": "BREAKFAST SANDWICH",
                        "info": "BREAKFAST SAUSAGE TOPPED WITH EGGS – SUNNYSIDE UP AND CHEDDAR CHEESE, SERVED WITH A HASH BROWN",
                        "cost": 200,
                        "id": 4
                    },
                    {
                        "name": "THE SMASH BURGER",
                        "info": "JUICY BEEF PATTIES WITH GOUDA CHEESE, ARUGULA AND A SPECIAL ROSE BURGER SAUCE, SERVED WITH FRENCH FRIES",
                        "cost": 165,
                        "id": 5
                    }
                ],
                "id": 7
            },
            {
                "subMenu": "big bites",
                "meals": [
                    {
                        "name": "STIR FRY STEAK RICE BOWL",
                        "info": "YOUR CHOICE OF RICE LOADED WITH STIR FRIED BEEF, CORN, SALSA AND SERVED WITH A SIDE OF COLESLAW",
                        "cost": 190,
                        "id": 0
                    },
                    {
                        "name": "GRILLED CHICKEN RICE BOWL",
                        "info": "YOUR CHOICE OF RICE LOADED WITH GRILLED CHICKEN, CORN, SALSA AND SERVED WITH COLESLAW",
                        "cost": 165,
                        "id": 1
                    },
                    {
                        "name": "HONEY GLAZED SALMON RICE BOWL",
                        "info":"YOUR CHOICE OF RICE LOADED WITH SALMON SAUTEED WITH HONEY, CORN, SALSA AND SERVED WITH COLESLAW",
                        "cost": 240,
                        "id": 2
                    },
                    {
                        "name": "SPICY PASTA",
                        "info": "PENNE PASTA TOSSED IN A SPICY MEAT SAUCE TOPPED WITH FRESH PARMESAN CHEESE",
                        "cost": 160,
                        "id": 3
                    },
                    {
                        "name": "CREAMY PARMESAN PASTA (NEW)",
                        "info": "LINGUINE PASTA TOSSED IN CREAMY ALFREDO SAUCE AND TOPPED WITH PARMESAN CHEESE",
                        "cost": 160,
                        "id": 4
                    },
                    {
                        "name": "SHRIMP LINGUINE",
                        "info": "LINGUINE PASTA TOSSED IN A TOMATO SAUCE TOPPED WITH GRILLED SHRIMP AND PARMASEAN CHEESE",
                        "cost": "210",
                        "id": 5
                    },
                    {
                        "name": "MAC N CHEESE",
                        "info":"CHEESY MACARONI PASTA BAKED TO PERFECTION AND TOPPED WITH A CHEESE MEDLEY, SERVED WITH A SIDE OF BREAD AND COLESLAW",
                        "cost": 180,
                        "id": 6
                    },
                    {
                        "name": "BACON MAC N CHEESE",
                        "info":"CHEESY MACARONI PASTA BAKED WITH BACON TOPPED WITH CHEESE MEDLEY, SERVED WITH A SIDE OF BREAD AND COLESLAW",
                        "cost": 230,
                        "id": 7
                    },
                ],
                "id": 8
            },
            {
                "subMenu": "ADD A SIDE",
                "meals": [
                    {
                        "name": "pancakes",
                        "info": "",
                        "cost": 60,
                        "id": 0
                    },
                    {
                        "name": "waffles",
                        "info": "",
                        "cost": 60,
                        "id": 1
                    },
                    {
                        "name": "french toast",
                        "info": "",
                        "cost": 65,
                        "id": 2
                    },
                    {
                        "name": "sausage",
                        "info": "",
                        "cost": 80,
                        "id": 3
                    },
                    {
                        "name": "bacon",
                        "info": "",
                        "cost": 55,
                        "id": 4
                    },
                    {
                        "name": "buttermilk chicken",
                        "info": "",
                        "cost": 95,
                        "id": 5
                    },
                    {
                        "name": "breakfast potatoes",
                        "info": "",
                        "cost": 60,
                        "id": 6
                    },
                    {
                        "name": "hash browns",
                        "info": "",
                        "cost": 50,
                        "id": 7
                    },
                    {
                        "name": "french fries",
                        "info": "",
                        "cost": 50,
                        "id": 8
                    },
                ],
                "id": 9, //
            }
        ],
        "id": 0
    },
    {
        "_id": 1,
        "value": "drinks",
        "viewValue": "Drinks",
        "options": [
            {
                "subMenu": "cocktails",
                "meals": [
                    {
                        "name": "Mimosa",
                        "info": "Prosecco, OJ",
                        "cost": 85,
                        "id": 0
                    },
                    {
                        "name": "Manmosa",
                        "info": "Whisky, Prosecco, OJ",
                        "cost": 95,
                        "id": 1
                    },
                    {
                        "name": "BUBBLING PEACH",
                        "info": "Prosecco, Peach Liqueur, Apricot Brandy",
                        "cost": 95,
                        "id": 2
                    },
                    {
                        "name": "PASSION FRUIT MARGARITA",
                        "info": "Tequila, Passion Fruit, Triple Sec",
                        "cost": 100,
                        "id": 3
                    },
                    {
                        "name": "Frose",
                        "info": "(Strawberry/ Lemon or Peach) Fruit Puree, Rancho, Vodka",
                        "cost": 100,
                        "id": 4
                    },
                    {
                        "name": "ROSE SANGRIA",
                        "info": "Red Wine, Rum, Mixed Fruits",
                        "cost": 95,
                        "id": 5
                    },
                    {
                        "name": "ROSE SPLASH",
                        "info": "Strawberry, Rum, Prosecco, Mint",
                        "cost": 160,
                        "id": 6
                    },
                    {
                        "name": "MR. ROSE",
                        "info": "Cognac, Prosecco",
                        "cost": 110,
                        "id": 7
                    },
                    {
                        "name": "OLD FASHION",
                        "info": "Whisky, Angostura Bitters, Sugar",
                        "cost": 85,
                        "id": 8
                    },
                    {
                        "name": "BLUE HAWAIIAN",
                        "info": "Vodka, Rum, Blue Curacao, Pineapple, Lemon",
                        "cost": 85,
                        "id": 9
                    }, 
                    {
                        "name": "Aperol spritz",
                        "info": "Prosecco, Aperol",
                        "cost": 90,
                        "id": 10
                    }, 
                    {
                        "name": "Aperol spritz",
                        "info": "Vodka, Earl Grey, Egg Whites",
                        "cost": 85,
                        "id": 11,
                    }, 
                    {
                        "name": "Aperol spritz",
                        "info": "Vodka, Espresso",
                        "cost": 85,
                        "id": 12,
                    }, 
                    {
                        "name": "margarita",
                        "info": "",
                        "cost":  85,
                        id: 13,
                    }, 
                    {
                        "name": "gin and tonic",
                        "info": "",
                        "cost":  85,
                        id: 14,
                    }, 
                    {
                        "name": "long island iced tea",
                        "info": "",
                        "cost":  90,
                        id: 15,
                    }, 
                    {
                        "name": "mojito",
                        "info": "",
                        "cost":  85,
                        id: 16,
                    }, 
                    {
                        "name": "pina colada",
                        "info": "",
                        "cost":  95,
                        id: 17,
                    }
                ],
                "id": 0
            }, 
            {
                "subMenu": "prosecco",
                "meals": [
                    {
                        "name": "Prosecco Veuve Du Vernay Rose",
                        "info": "",
                        "cost": 35,
                        "id": 0
                    },
                ],
                "id": 1, 
            }, 
            {
                "subMenu": "shots",
                "meals": [
                    {
                        "name": "Tequila",
                        "info": "",
                        "cost": 45,
                        "id": 0
                    },
                    {
                        "name": "Vodka",
                        "info": "",
                        "cost": 45,
                        "id": 1
                    },
                    {
                        "name": "Whisky",
                        "info": "",
                        "cost": 45,
                        "id": 2
                    },
                    {
                        "name": "cognac",
                        "info": "",
                        "cost": 45,
                        "id": 3
                    },
                ],
                "id": 2, 
                "metadata": "*ask your server about our shot box"
            }, 
            {
                "subMenu": "shooters",
                "meals": [
                    {
                        "name": "the bj",
                        "info": "",
                        "cost": 50,
                        "id": 0
                    },
                    {
                        "name": "brain damage",
                        "info": "",
                        "cost": 50,
                        "id": 1
                    },
                    {
                        "name": "rose special",
                        "info": "",
                        "cost": 50,
                        "id": 2
                    },
                    {
                        "name": "tequila sunrise",
                        "info": "",
                        "cost": 50,
                        "id": 3
                    },
                ],
                "id": 3, 
                "metadata": "*ask your server about our shot box"
            },                 
            {
                "subMenu": "wine",
                "meals": [
                    {
                        "name": "mosketto red",
                        "info": "Sweet Wine",
                        "cost": 400,
                        "id": 0
                    },
                    {
                        "name": "mosketto white",
                        "info": "Sweet Wine",
                        "cost": 45,
                        "id": 1
                    },
                    
                ],
                "id": 6, 
            },
            {
                "subMenu": "boozy towers",
                "meals": [
                    {
                        "name": "mimosa",
                        "info": "",
                        "cost": 850,
                        "id": 0
                    },
                    {
                        "name": "manmosa",
                        "info": "",
                        "cost": 900,
                        "id": 1
                    },
                    {
                        "name": "rose splash",
                        "info": "",
                        "cost": 950,
                        "id": 2
                    },
                    {
                        "name": "rose sangria",
                        "info": "",
                        "cost": 900,
                        "id": 3
                    },
                    {
                        "name": "peach sangria",
                        "info": "",
                        "cost": 900,
                        "id": 4
                    },
                    {
                        "name": "bubbling peach",
                        "info": "",
                        "cost": 950,
                        "id": 5
                    },
                    {
                        "name": "tropical punch",
                        "info": "",
                        "cost": 900,
                        "id": 6
                    },
                    {
                        "name": "rose vous",
                        "info": "",
                        "cost": 700,
                        "id": 7
                    },
                ],
                "id": 7, 
            },
            {
                "subMenu": "mimosa flight",
                "meals": [
                    {
                        "name": "mimosa flight",
                        "info": "",
                        "cost": 320,
                        "id": 0
                    },
                ],
                "id": 5, 
            },
            {
                "subMenu": "hot drinks",
                "meals": [
                    {
                        "name": "espresso",
                        "info": "",
                        "cost": 40,
                        "id": 0
                    },
                    {
                        "name": "double espresso",
                        "info": "",
                        "cost": 75,
                        "id": 1
                    },
                    {
                        "name": "americano",
                        "info": "",
                        "cost": 40,
                        "id": 2
                    },
                    {
                        "name": "CAFÉ AU LAIT",
                        "info": "",
                        "cost": 45,
                        "id": 3
                    },
                    {
                        "name": "tea",
                        "info": "english / green tea",
                        "cost": 35,
                        "id": 4
                    },
                    {
                        "name": "cappucino",
                        "info": "",
                        "cost": 45,
                        "id": 5
                    },
                    {
                        "name": "latte",
                        "info": "",
                        "cost": 50,
                        "id": 6
                    },
                   
                ],
                "id": 8, 
                "metadata": "*almond milk 25"
            },
            {
                "subMenu": "beer",
                "meals": [
                    {
                        "name": "club (mini)",
                        "info": "",
                        "cost": 35,
                        "id": 0
                    },
                    {
                        "name": "heineken",
                        "info": "",
                        "cost": 45,
                        "id": 1
                    },
                    
                ],
                "id": 4, 
            },
            {
                "subMenu": "mock tails",
                "meals": [
                    {
                        "name": "rose vous",
                        "info": "Strawberry",
                        "cost": 70,
                        "id": 0
                    },
                    {
                        "name": "pinky promise",
                        "info": "strawberry, Lemonade",
                        "cost": 75,
                        "id": 1
                    },
                    {
                        "name": "lady rose",
                        "info": "strawberry, mint",
                        "cost": 75,
                        "id": 2
                    },
                    {
                        "name": "rose tea",
                        "info": "Raspberry Ice Tea, Mint",
                        "cost": 70,
                        "id": 3
                    },
                    {
                        "name": "peach slushy",
                        "info": "peach",
                        "cost": 80,
                        "id": 4
                    },
                    {
                        "name": "blue crush",
                        "info": "Pineapple, Blue Curacao, Rantcho, Ginger",
                        "cost": 75,
                        "id": 5
                    },
                    {
                        "name": "hibiscus coconut refresher",
                        "info": "Hibiscus, Coconut Water, Cream",
                        "cost": 70,
                        "id": 6
                    },
                   
                ],
                "id": 9, 
            },
             
            {
                "subMenu": "fresh juice",
                "meals": [
                    {
                        "name": "orange",
                        "info": "",
                        "cost": 40,
                        "id": 0
                    },
                    {
                        "name": "pineapple",
                        "info": "",
                        "cost": 40,
                        "id": 1
                    },
                    {
                        "name": "pineapple ginger",
                        "info": "",
                        "cost": 40,
                        "id": 2
                    },
                    {
                        "name": "carrot / orange & pineapple",
                        "info": "",
                        "cost": 40,
                        "id": 3
                    },
                    {
                        "name": "pineapple & passionfruit",
                        "info": "",
                        "cost": 40,
                        "id": 4
                    },
                ],
                "id": 10, 
                "metadata": "*add ginger GHS 10"
            },
            {
                "subMenu": "ICED Drinks",
                "meals": [
                    {
                        "name": "Iced Americano",
                        "info": "",
                        "cost": 60,
                        "id": 0
                    },
                    {
                        "name": "Iced Coffee",
                        "info": "",
                        "cost": 60,
                        "id": 1
                    },
                    {
                        "name": "Caramel Iced Coffee",
                        "info": "",
                        "cost": 60,
                        "id": 2
                    },
                    {
                        "name": "Rose Iced Coffee",
                        "info": "",
                        "cost": 60,
                        "id": 3
                    },
                ],
                "id": 11, 
            }, 
            {
                "subMenu": "milkshakes",
                "meals": [
                    {
                        "name": "BROWNIE CHOCOLATE CHUNK",
                        "info": "",
                        "cost": 90,
                        "id": 0
                    },
                    {
                        "name": "STRAWBERRY CHEESECAKE",
                        "info": "",
                        "cost": 90,
                        "id": 1
                    },
                    {
                        "name": "CONFETTI",
                        "info": "",
                        "cost": 90,
                        "id": 2
                    },
                ],
                "id": 12, 
            },
            {
                "subMenu": "Energy",
                "meals": [
                    {
                        "name": "red bull",
                        "info": "",
                        "cost": 50,
                        "id": 0
                    },
                    
                ],
                "id": 13, 
            }, 
            {
                "subMenu": "Sodas",
                "meals": [
                    {
                        "name": "COCA COLA",
                        "info": "",
                        "cost": 30,
                        "id": 0
                    },
                    {
                        "name": "fanta",
                        "info": "",
                        "cost": 30,
                        "id": 1
                    },
                    {
                        "name": "Sprite",
                        "info": "",
                        "cost": 30,
                        "id": 2
                    },
                    
                ],
                "id": 14, 
            },
            {
                "subMenu": "water",
                "meals": [
                    {
                        "name": "Healsi Water",
                        "info": "",
                        "cost": 25,
                        "id": 0
                    },
                    {
                        "name": "Veon Water",
                        "info": "",
                        "cost": 15,
                        "id": 1
                    },
                    {
                        "name": "Verna Water",
                        "info": "",
                        "cost": 15,
                        "id": 2
                    },
                    {
                        "name": "sparkling Water",
                        "info": "",
                        "cost": 35,
                        "id": 3
                    },
                ],
                "id": 15, 
            }, 
        ],
        "id": 2
    }
]
