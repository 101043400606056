import React from 'react'
import Logo from "./../../../src/images/rose-rm-bg.png";
import "./style.css"
import { Link } from 'react-router-dom';


function Banner() {
  return (
    <div className="banner">
      <div className='wrapper'>
        <div className="img-container" >
          <Link to="/">
            <img src={Logo} alt="logo circle" />
          </Link>
        </div>
        <div className='info'>
            <p className='poppins-semibold-italic'>BREAKFAST IS OUR THING... WE LOVE IT HERE!</p>
        </div>
      </div>
    </div>
  )
}

export default Banner