import {React, useState} from "react";
import { Link } from "react-router-dom";

import "./styles.css";
import fbIcon from "./../../../src/images/facebook-icon.png";
import igIcon from "./../../../src/images/mdi_instagram.png";
import tiktokIcon from "./../../../src/images/tiktok.png";

const Footer = () => {
    const [clickCount, setClickCount] = useState(0);

    const handleClick = () => {
        setClickCount(prevCount => {
            const newCount = prevCount + 1;
            if (newCount === 10) {
                console.log("NexaNova did it! ", clickCount);
            }
            return newCount;
        });
    };
    return (
        <footer className="footer">
            <div className="footer-wrapper">
                <div className="copyright">
                    <p>
                        <span onClick={handleClick}>&copy; 2024 ROSÉ GARDEN </span>
                        <span className="xtra"> || All Rights Reserved  </span>
                    </p>
                </div>
                <div className="follow-us">
                    <div className="social-icons">
                        <Link to="https://m.facebook.com/100037145086778/"  target="_blank" rel="noopener noreferrer">
                            <img src={fbIcon} alt="Facebook" />
                        </Link>
                        <Link to="https://www.instagram.com/rosegardengh/profilecard/?igsh=dzFvd29jcjNiZWo5"  target="_blank" rel="noopener noreferrer">
                            <img src={igIcon} alt="Instagram" />
                        </Link>
                        <Link to="https://www.tiktok.com/@rosegardengh?_t=8rzaz05bAEH&_r=1"  target="_blank" rel="noopener noreferrer">
                            <img src={tiktokIcon} alt="TikTok" />
                        </Link>
                    </div>
                </div>
                <div className='legal'>
                    <p>Terms of Service</p>
                    <p>Privacy Policy</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
