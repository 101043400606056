import {React, useState, } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import Header from "../header";
import mail from './../../images/mail.png'
import mobile from './../../images/old-mobile.png'
import phone from './../../images/phone.png'
import fbIcon from "./../../../src/images/facebook-icon.png";
import igIcon from "./../../../src/images/mdi_instagram.png";
import tkIcon from "./../../../src/images/tiktok.png";
import Banner from "../banner";
import Footer from "../footer";
const Contact = () => {
    const [activePage, ] = useState("contact");

    return (
        <div className="contact-wrapper">
            <Header activePageStr={activePage}/>
            <Banner/>
            <div className="background-wrapper">
                <div className="contact-container">
                    <div className="contact-form-section">
                        <div>
                            <p className="form-heading poppins-bold">
                                Get in <span className="form-heading">Touch</span>
                            </p>
                            <p>
                                Welcome to the vibrant and lively world of Rosé Garden, 
                                where every moment is a celebration of delectable delights 
                                and good times! Nestled in the heart of Accra, this gastronomic 
                                haven is not just a restaurant; it’s an experience that tantalizes
                                your taste buds and elevates your mood.
                            </p>
                        </div>
                        
                        
                        <div className="contact-details">
                            <div className="contact">
                                <div className="img-container">
                                    <img src={mobile} alt=""/>
                                </div>
                                <div className="details">
                                    <p className="heading">Mobile</p>
                                    <p className="info">+233 59 481 6437</p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className="img-container">
                                    <img src={phone} alt=""/>
                                </div>
                                <div className="details">
                                    <p className="heading">Phone</p>
                                    <p className="info">+233 59 481 6437</p>
                                </div>
                            </div>
                            <div className="contact">
                                <div className="img-container">
                                    <img src={mail} alt=""/>
                                </div>
                                <div className="details">
                                    <p className="heading">email</p>
                                    <p className="info">rosegardenaccra@gmail.com</p>
                                </div>
                            </div>
                            
                        </div>

                        <div className="contact-socials social-icons">
                            <p>Follow us: </p>
                            <Link to="https://m.facebook.com/100037145086778/" target="_blank" rel="noopener noreferrer">
                            <img src={fbIcon} alt="facebook-icon"/>
                            </Link>
                            <Link to="https://www.tiktok.com/@rosegardengh?_t=8rzaz05bAEH&_r=1"  target="_blank" rel="noopener noreferrer">
                            <img src={tkIcon} alt="tiktok-icon"/>
                            </Link>
                            <Link to="https://www.instagram.com/rosegardengh/profilecard/?igsh=dzFvd29jcjNiZWo5"  target="_blank" rel="noopener noreferrer">
                            <img src={igIcon} alt="instagram-icon"  />
                            </Link>
                        </div>
                    </div>
                    <div className="contact-map-section">
                        <iframe
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=30%20Orphan%20Cres,%20Accra+(Ros%C3%A9%20Garden)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                            width="100%"
                            height="100%"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="map"
                        ></iframe>
                    </div>
                </div>
            </div>   
            <Footer/>         
        </div>
    );
};

export default Contact;
